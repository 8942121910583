import React, { useRef } from "react";
import { useEffect, useState } from "react";
import tw from "twin.macro";
import './ConsoleHeader.css';
import {generateNextFrame, generateNextName} from './DrawService.js';
import {generateNextFrameV2, updateImageState, moveFramePosition} from './DrawServiceV2.js';
import {fetchNextImage} from './AsciiLoaderService.js';
import {shuffleString, sortSingleCharacter} from './ShuffleUtility.js';
// import SettingsIcon from '@mui/icons-material/Settings';

const Console = tw.div`z-0 bg-black relative overflow-hidden`;

const TopConsole = tw.div`z-20 absolute w-full overflow-hidden`;
const ConsoleText = tw.p`text-[22vw] leading-[20vw] sm:text-[15vw] sm:leading-[15vw]`;
// const Settings = tw.p`z-30 fixed`;
const TopLeftConsoleRow = tw(ConsoleText)`font-mono text-white opacity-50`;
const TopRightConsoleRow = tw(ConsoleText)`font-mono text-white opacity-50`;

const BottomConsole = tw.div`z-1 absolute w-full bg-[#1e293b] overflow-hidden whitespace-nowrap`;
const OverlayConsole = tw.div`z-30 absolute w-full `;
const BottomConsoleRow = tw.div`overflow-hidden whitespace-pre`;
const BackgroundTextRow = tw.p`font-mono text-xl`;


export default ({ //eslint-disable-line
}) => {
  const frontRefreshRate = 200; //eslint-disable-line
  const frontRefreshRateSlow = 4000;
  const backRefreshRate = 200;
  const tockRefreshRate = 100;
  const firstModel = generateNextFrame([]);
  const [model, setModel] = useState(firstModel);
  const name1 = 'Edison';
  const name2 = 'Earnest';
  const [currentName1, setName1] = useState(name1);
  const [currentName2, setName2] = useState(name2);

  const imageType = 'space-1';

  let refName1 = useRef(name1);
  let refName2 = useRef(name2);

  let rowOffset = useRef(0);
  let colOffset = useRef(0);

  let maxRows = useRef(0);
  let maxCols = useRef(0);

  let currentImageIndex = useRef(0);
  let currentDirection = useRef(1); //direction of the current frame incriments (e.g. 11 => 12 if direction === 1)
  let modelCache = useRef([]);

  let indexCache = useRef(0);
  let updatePosition = useRef(false);

  const consoleDebugging = false;

  const onTockIntervalChange = () => {
    const newState = updateImageState(0, currentDirection.current, currentImageIndex.current);

    if (newState.currentIndex !== currentImageIndex.current) {
      // console.log('new index achieved', newState.currentIndex)
      // setImageIndex(newState.currentIndex);
      currentImageIndex.current = newState.currentIndex;
    }
    if (newState.direction !== currentDirection.current) {
      currentDirection.current = newState.direction;
    }
  };

  const onBackIntervalChange = () => {
    //todo: figure out ratios, or maybe I need to figure out if I can stretch out text or something? Width doesn't work because zooming in/out doesn't 
    // console.log('width', ref.current ? ref.current.offsetWidth : 0);

    if (consoleDebugging) {
      console.log('Next Interval Hit: (currentIndex) = ', currentImageIndex.current);
    }

    if (modelCache.current.length === 0 || !modelCache.current[currentImageIndex.current]){
      fetchNextImage(imageType, currentImageIndex.current).then((r) => {
  
        modelCache.current[currentImageIndex.current] = r.split('\r\n');

        if (modelCache.current[currentImageIndex.current].length === 1) {
          console.warn('linux line endings found!');
          modelCache.current[currentImageIndex.current] = r.split('\n');
        }

        if (maxRows.current === 0) {
          maxRows.current = modelCache.current[currentImageIndex.current].length;
        }
        if (maxCols.current === 0) {
          maxCols.current = modelCache.current[currentImageIndex.current][0].length;
        }

        let nextFrame = generateNextFrameV2(modelCache.current[currentImageIndex.current], currentImageIndex, rowOffset.current, colOffset.current);
        setModel(nextFrame);
      })
    } else {
      let nextFrame = generateNextFrameV2(modelCache.current[currentImageIndex.current], currentImageIndex, rowOffset.current, colOffset.current);
      setModel(nextFrame);
    }

    if (currentImageIndex.current === 0 || currentImageIndex.current === 100) {
      updatePosition.current = true;
    } else if (currentImageIndex.current === 50 && updatePosition.current) {
      const newPosition = moveFramePosition(rowOffset.current, colOffset.current, maxRows.current, maxCols.current, currentImageIndex.current);
      rowOffset.current = newPosition.row;
      colOffset.current = newPosition.col;
      updatePosition.current = false;
    }
  };

  const onFrontIntervalChange = () => { //eslint-disable-line
    //legacy - random character names
    let nextName1 = generateNextName(currentName1, name1);
    let nextName2 = generateNextName(currentName2, name2);
    setName1(nextName1);
    setName2(nextName2);
  };

  const onFrontIntervalShuffle = () => {
    //shuffle names - should occur less frequently
    let nextName1 = shuffleString(name1, 2);
    let nextName2 = shuffleString(name2, 2);

    setName1(nextName1);
    setName2(nextName2);
    refName1.current = nextName1;
    refName2.current = nextName2;

    indexCache.current = 0;
  };

  const onFrontIntervalRebuilder = () => {
    // rebuild names - should occur more frequently
    const nameResult1 = sortSingleCharacter(refName1.current, name1, indexCache.current);
    const nameResult2 = sortSingleCharacter(refName2.current, name2, indexCache.current);
    indexCache.current = indexCache.current + 1;

    if (nameResult1[0]) {
      setName1(nameResult1[1]);
      refName1.current = nameResult1[1];
    }
    if (nameResult2[0]) {
      setName2(nameResult2[1]);
      refName2.current = nameResult2[1];
    }
  };

  useEffect(() => {
    const backConsoleInterval = setInterval(() => {
      // console.log('interval hit!');
      onBackIntervalChange();
    }, backRefreshRate);

    const tockInterval = setInterval(() => {
      // console.log('interval hit!');
      onTockIntervalChange();
    }, tockRefreshRate);

    // const frontConsoleInterval = setInterval(() => {
    //   // console.log('interval hit!');
    //   onFrontIntervalChange();
    // }, frontRefreshRate);

    const frontConsoleIntervalShuffle = setInterval(() => {
      // console.log('interval hit!');
      onFrontIntervalShuffle();
    }, frontRefreshRateSlow);

    const frontConsoleIntervalBuilder = setInterval(() => {
      // console.log('interval hit!');
      onFrontIntervalRebuilder();
    }, frontRefreshRate);

    return () => {
      clearInterval(backConsoleInterval);
      // clearInterval(frontConsoleInterval);
      clearInterval(frontConsoleIntervalShuffle);
      clearInterval(frontConsoleIntervalBuilder);
      clearInterval(tockInterval);
    }
  }, []);//eslint-disable-line

  function TopConsoleContent() {
    return <div className="vertical-center center">
      <TopLeftConsoleRow>{currentName1}</TopLeftConsoleRow>
      <TopRightConsoleRow>{currentName2}</TopRightConsoleRow>
    </div>
  }

  function genRow(rowText) {
    const pattern = /([\w\W])\1*/g;
    const rowMatches = rowText.match(pattern);
    // console.log(rowMatches);

    const styledRows = rowMatches.map(row => {
      if (row.length > 0) {
        switch (row[0]) {
          case '&':
            return <span className="brightest-text">{row}</span>;
          case '$':
          case 'X':
            return <span className="bright-text">{row}</span>;
          case '.':
            return <span className="darkest-text">{row}</span>;
          case ':':
          case ';':
            return <span className="dark-text">{row}</span>;
          default:
            return <span className="mutual-text">{row}</span>;
        }
      } else {
        return <span></span>;
      }
    });

    return (<BackgroundTextRow style={{fontSize: '1vh', height: '1vh', lineHeight: '1vh'}}>
        {styledRows}
      </BackgroundTextRow>);

    // return (<BackgroundTextRow className="mutual-text" style={{fontSize: '1vh', height: '1vh', lineHeight: '1vh'}}>
    //     <BackgroundLightTextSpan className="bright-text">a</BackgroundLightTextSpan>
    //     <BackgroundDarkTextSpan className="dark-text">b</BackgroundDarkTextSpan>
    //     {rowText}
    //   </BackgroundTextRow>);
  }

  function BottomConsoleContent() {
    return model.map(row => {
      return <BottomConsoleRow>{genRow(row)}</BottomConsoleRow>;
    });
  }

  // const fs = 70;   
  return (
    <>
      <Console className="container" style={{ height: '70vh'}}>
      <OverlayConsole style={{ height: '70vh'}}></OverlayConsole>
        <TopConsole className="center" style={{ height: '70vh'}}>
          {TopConsoleContent()}
        </TopConsole>
        <BottomConsole className="center" style={{ height: '70vh' }}>
          {BottomConsoleContent()}
        </BottomConsole>
      </Console>
      {/* <Settings>
        <SettingsIcon sx={{ position: 'fixed', margin: '5px', color: 'black', fontSize: fs }} />
      </Settings> */}
      {/* <Header links={navLinks} /> */}
    </>
  );
};
