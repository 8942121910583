import React from "react";
import { useEffect, useState } from "react";
import tw from "twin.macro";
import './ConsoleHeader.css';
import { useNavigate } from "react-router-dom";
import {generateNextButtonFrame} from './DrawService.js';
import {shuffleString, sortSingleCharacter} from './ShuffleUtility.js';

const NavContainer = tw.div`z-20 grid grid-cols-3 gap-[.25vw] content-stretch`;
const ConsoleNavButton = tw.div`z-20 hover:bg-blue-700`;
const NavButton = tw.div`relative z-30 w-full h-full overflow-hidden rounded-[10px]`;
const NavButtonText = tw.p`absolute text-center h-full w-full`;
const TextBackground = tw.p`text-white text-left opacity-100 whitespace-pre font-mono`;

const NavButtonSize = '20vh';

export default ({ //eslint-disable-line
}) => {
  const navigate = useNavigate();
  const buttonRefreshRate = 300;
  const [aboutModel, setAboutModel] = useState(generateNextButtonFrame([]));
  const [blogsModel, setBlogsModel] = useState(generateNextButtonFrame([]));
  const [recipesModel, setRecipesModel] = useState(generateNextButtonFrame([]));

  const originalAbout = 'About';
  const originalBlogs = 'Blogs';
  const originalRecipes = 'Recipes';

  const [aboutText, setAbout] = useState(originalAbout);
  const [blogsText, setBlogs] = useState(originalBlogs);
  const [recipesText, setRecipes] = useState(originalRecipes);

  const onButtonIntervalChange = () => {
    let nextAboutFrame = generateNextButtonFrame(aboutModel);
    let nextBlogsFrame = generateNextButtonFrame(blogsModel);
    let nextRecipesFrame = generateNextButtonFrame(recipesModel);
    setAboutModel(nextAboutFrame);
    setBlogsModel(nextBlogsFrame);
    setRecipesModel(nextRecipesFrame);
  };

  const onHoverHandler = (textDisplayed, originalText, setText) => {
    let shuffledText = shuffleString(textDisplayed, 2);
    setText(shuffledText);

    let n = 10; //maybe could set this to the longest text? todo...
    let sortedResult = shuffledText;
    for(let i = 0; i < n; i++) {
      const result = sortSingleCharacter(sortedResult, originalText, i);
      if (result[0]){
        textChange(result[1], setText);
        sortedResult = result[1];
      }
    }
  }

  function textChange(text, setText) {
    setTimeout(function () {
      setText(text);
    }, 200);
  }

  const onHoverLeaveHandler = (textDisplayed, originalText, setText) => {
    setText(originalText);
  }

  const onAboutNavigation = () => {
    navigate("/about");
  }

  const onBlogNavigation = () => {
    navigate("/blogs");
  }

  const onRecipieNavigation = () => {
    navigate("/recipes");
  }

  useEffect(() => {
    const buttonConsoleInterval = setInterval(() => {
      // console.log('interval hit!');
      onButtonIntervalChange();
    }, buttonRefreshRate);

    return () => {
      clearInterval(buttonConsoleInterval);
    }
  }, []);//eslint-disable-line

  function ButtonBackgroundTex(model) {
    return model.map(row => {
      return <TextBackground style={{fontSize: '1vh', height: '1vh', lineHeight: '1vh'}}>{row}</TextBackground>;
    });
  }

  function NavTextContent(text, model, color = "yellow") {
    return <NavButton>
      <NavButtonText><div style={{fontSize: '8vw'}} className="vertical-center nav-button-text">{text}</div></NavButtonText>
      {ButtonBackgroundTex(model)}
    </NavButton>
  }

  return (
    <>
      <NavContainer className="containerNavButtons" style={{ height: NavButtonSize }}>
        <ConsoleNavButton 
          onClick={onAboutNavigation}
          onMouseEnter={() => onHoverHandler(aboutText, originalAbout,setAbout)}
          onMouseLeave={() => onHoverLeaveHandler(aboutText, originalAbout, setAbout)}
          className="center yellow"
          style={{ height: NavButtonSize }}
        >
          {NavTextContent(aboutText, aboutModel, 'yellow')}
        </ConsoleNavButton>
        <ConsoleNavButton
          onClick={onBlogNavigation}
          onMouseEnter={() => onHoverHandler(blogsText, originalBlogs,setBlogs)}
          onMouseLeave={() => onHoverLeaveHandler(blogsText, originalBlogs, setBlogs)}
          className="center green"
          style={{ height: NavButtonSize }}
        >
          {NavTextContent(blogsText, blogsModel, 'green')}
        </ConsoleNavButton>
        <ConsoleNavButton
          onClick={onRecipieNavigation}
          onMouseEnter={() => onHoverHandler(recipesText, originalRecipes,setRecipes)}
          onMouseLeave={() => onHoverLeaveHandler(recipesText, originalRecipes, setRecipes)}
          className="center blue"
          style={{ height: NavButtonSize }}
        >
          {NavTextContent(recipesText, recipesModel, 'blue')}
        </ConsoleNavButton>
      </NavContainer>
    </>
  );
};
