function findNextCharInString(str, c, i) {
  const j = i;
  while (i < str.length) {
    if (str[i++] === c) {
      return i - 1;
    }
  }
  return j;
}

export function shuffleString(str, n = 1) {
  const strArray = [...str];
  for (let i = 0; i < n; i++) {
    strArray.sort(()=>Math.random()-.5)
  }
  return strArray.join('');
};

export function sortSingleCharacter(unsortedStr, sortedStr, index) {
  let isUpdated = false;
  let i = index;

  // single insert sort
  if (i < unsortedStr.length) {
    if (i < unsortedStr.length) {
      isUpdated = true;
      var strArray = [...unsortedStr];
      var charToFind = sortedStr[i];
      var swapIndex = findNextCharInString(unsortedStr, charToFind, i);
      strArray[swapIndex] = strArray[i];
      strArray[i] = charToFind;
      unsortedStr = strArray.join('');
    }
  }

  return [isUpdated, unsortedStr];
};
