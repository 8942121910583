import WaterColor0 from "components/asciiArt/water-color-universe/water-color-00.txt";
import WaterColor1 from "components/asciiArt/water-color-universe/water-color-01.txt";
import WaterColor2 from "components/asciiArt/water-color-universe/water-color-02.txt";
import WaterColor3 from "components/asciiArt/water-color-universe/water-color-03.txt";
import WaterColor4 from "components/asciiArt/water-color-universe/water-color-04.txt";
import WaterColor5 from "components/asciiArt/water-color-universe/water-color-05.txt";
import WaterColor6 from "components/asciiArt/water-color-universe/water-color-06.txt";
import WaterColor7 from "components/asciiArt/water-color-universe/water-color-07.txt";
import WaterColor8 from "components/asciiArt/water-color-universe/water-color-08.txt";
import WaterColor9 from "components/asciiArt/water-color-universe/water-color-09.txt";
import WaterColor10 from "components/asciiArt/water-color-universe/water-color-10.txt";

import WaterColor11 from "components/asciiArt/water-color-universe/water-color-11.txt";
import WaterColor12 from "components/asciiArt/water-color-universe/water-color-12.txt";
import WaterColor13 from "components/asciiArt/water-color-universe/water-color-13.txt";
import WaterColor14 from "components/asciiArt/water-color-universe/water-color-14.txt";
import WaterColor15 from "components/asciiArt/water-color-universe/water-color-15.txt";
import WaterColor16 from "components/asciiArt/water-color-universe/water-color-16.txt";
import WaterColor17 from "components/asciiArt/water-color-universe/water-color-17.txt";
import WaterColor18 from "components/asciiArt/water-color-universe/water-color-18.txt";
import WaterColor19 from "components/asciiArt/water-color-universe/water-color-19.txt";
import WaterColor20 from "components/asciiArt/water-color-universe/water-color-20.txt";

import WaterColor21 from "components/asciiArt/water-color-universe/water-color-21.txt";
import WaterColor22 from "components/asciiArt/water-color-universe/water-color-22.txt";
import WaterColor23 from "components/asciiArt/water-color-universe/water-color-23.txt";
import WaterColor24 from "components/asciiArt/water-color-universe/water-color-24.txt";
import WaterColor25 from "components/asciiArt/water-color-universe/water-color-25.txt";
import WaterColor26 from "components/asciiArt/water-color-universe/water-color-26.txt";
import WaterColor27 from "components/asciiArt/water-color-universe/water-color-27.txt";
import WaterColor28 from "components/asciiArt/water-color-universe/water-color-28.txt";
import WaterColor29 from "components/asciiArt/water-color-universe/water-color-29.txt";
import WaterColor30 from "components/asciiArt/water-color-universe/water-color-30.txt";

import WaterColor31 from "components/asciiArt/water-color-universe/water-color-31.txt";
import WaterColor32 from "components/asciiArt/water-color-universe/water-color-32.txt";
import WaterColor33 from "components/asciiArt/water-color-universe/water-color-33.txt";
import WaterColor34 from "components/asciiArt/water-color-universe/water-color-34.txt";
import WaterColor35 from "components/asciiArt/water-color-universe/water-color-35.txt";
import WaterColor36 from "components/asciiArt/water-color-universe/water-color-36.txt";
import WaterColor37 from "components/asciiArt/water-color-universe/water-color-37.txt";
import WaterColor38 from "components/asciiArt/water-color-universe/water-color-38.txt";
import WaterColor39 from "components/asciiArt/water-color-universe/water-color-39.txt";
import WaterColor40 from "components/asciiArt/water-color-universe/water-color-40.txt";

import WaterColor41 from "components/asciiArt/water-color-universe/water-color-41.txt";
import WaterColor42 from "components/asciiArt/water-color-universe/water-color-42.txt";
import WaterColor43 from "components/asciiArt/water-color-universe/water-color-43.txt";
import WaterColor44 from "components/asciiArt/water-color-universe/water-color-44.txt";
import WaterColor45 from "components/asciiArt/water-color-universe/water-color-45.txt";
import WaterColor46 from "components/asciiArt/water-color-universe/water-color-46.txt";
import WaterColor47 from "components/asciiArt/water-color-universe/water-color-47.txt";
import WaterColor48 from "components/asciiArt/water-color-universe/water-color-48.txt";
import WaterColor49 from "components/asciiArt/water-color-universe/water-color-49.txt";
import WaterColor50 from "components/asciiArt/water-color-universe/water-color-50.txt";

import WaterColor51 from "components/asciiArt/water-color-universe/water-color-51.txt";
import WaterColor52 from "components/asciiArt/water-color-universe/water-color-52.txt";
import WaterColor53 from "components/asciiArt/water-color-universe/water-color-53.txt";
import WaterColor54 from "components/asciiArt/water-color-universe/water-color-54.txt";
import WaterColor55 from "components/asciiArt/water-color-universe/water-color-55.txt";
import WaterColor56 from "components/asciiArt/water-color-universe/water-color-56.txt";
import WaterColor57 from "components/asciiArt/water-color-universe/water-color-57.txt";
import WaterColor58 from "components/asciiArt/water-color-universe/water-color-58.txt";
import WaterColor59 from "components/asciiArt/water-color-universe/water-color-59.txt";
import WaterColor60 from "components/asciiArt/water-color-universe/water-color-60.txt";

import WaterColor61 from "components/asciiArt/water-color-universe/water-color-61.txt";
import WaterColor62 from "components/asciiArt/water-color-universe/water-color-62.txt";
import WaterColor63 from "components/asciiArt/water-color-universe/water-color-63.txt";
import WaterColor64 from "components/asciiArt/water-color-universe/water-color-64.txt";
import WaterColor65 from "components/asciiArt/water-color-universe/water-color-65.txt";
import WaterColor66 from "components/asciiArt/water-color-universe/water-color-66.txt";
import WaterColor67 from "components/asciiArt/water-color-universe/water-color-67.txt";
import WaterColor68 from "components/asciiArt/water-color-universe/water-color-68.txt";
import WaterColor69 from "components/asciiArt/water-color-universe/water-color-69.txt";
import WaterColor70 from "components/asciiArt/water-color-universe/water-color-70.txt";

import WaterColor71 from "components/asciiArt/water-color-universe/water-color-71.txt";
import WaterColor72 from "components/asciiArt/water-color-universe/water-color-72.txt";
import WaterColor73 from "components/asciiArt/water-color-universe/water-color-73.txt";
import WaterColor74 from "components/asciiArt/water-color-universe/water-color-74.txt";
import WaterColor75 from "components/asciiArt/water-color-universe/water-color-75.txt";
import WaterColor76 from "components/asciiArt/water-color-universe/water-color-76.txt";
import WaterColor77 from "components/asciiArt/water-color-universe/water-color-77.txt";
import WaterColor78 from "components/asciiArt/water-color-universe/water-color-78.txt";
import WaterColor79 from "components/asciiArt/water-color-universe/water-color-79.txt";
import WaterColor80 from "components/asciiArt/water-color-universe/water-color-80.txt";

import WaterColor81 from "components/asciiArt/water-color-universe/water-color-81.txt";
import WaterColor82 from "components/asciiArt/water-color-universe/water-color-82.txt";
import WaterColor83 from "components/asciiArt/water-color-universe/water-color-83.txt";
import WaterColor84 from "components/asciiArt/water-color-universe/water-color-84.txt";
import WaterColor85 from "components/asciiArt/water-color-universe/water-color-85.txt";
import WaterColor86 from "components/asciiArt/water-color-universe/water-color-86.txt";
import WaterColor87 from "components/asciiArt/water-color-universe/water-color-87.txt";
import WaterColor88 from "components/asciiArt/water-color-universe/water-color-88.txt";
import WaterColor89 from "components/asciiArt/water-color-universe/water-color-89.txt";
import WaterColor90 from "components/asciiArt/water-color-universe/water-color-90.txt";

import WaterColor91 from "components/asciiArt/water-color-universe/water-color-91.txt";
import WaterColor92 from "components/asciiArt/water-color-universe/water-color-92.txt";
import WaterColor93 from "components/asciiArt/water-color-universe/water-color-93.txt";
import WaterColor94 from "components/asciiArt/water-color-universe/water-color-94.txt";
import WaterColor95 from "components/asciiArt/water-color-universe/water-color-95.txt";
import WaterColor96 from "components/asciiArt/water-color-universe/water-color-96.txt";
import WaterColor97 from "components/asciiArt/water-color-universe/water-color-97.txt";
import WaterColor98 from "components/asciiArt/water-color-universe/water-color-98.txt";
import WaterColor99 from "components/asciiArt/water-color-universe/water-color-99.txt";
import WaterColor100 from "components/asciiArt/water-color-universe/water-color-100.txt";

const waterColorMap = [
  WaterColor0, WaterColor1, WaterColor2, WaterColor3, WaterColor4, WaterColor5, WaterColor6, WaterColor7, WaterColor8, WaterColor9,
  WaterColor10, WaterColor11, WaterColor12, WaterColor13, WaterColor14, WaterColor15, WaterColor16, WaterColor17, WaterColor18, WaterColor19,
  WaterColor20, WaterColor21, WaterColor22, WaterColor23, WaterColor24, WaterColor25, WaterColor26, WaterColor27, WaterColor28, WaterColor29,
  WaterColor30, WaterColor31, WaterColor32, WaterColor33, WaterColor34, WaterColor35, WaterColor36, WaterColor37, WaterColor38, WaterColor39,
  WaterColor40, WaterColor41, WaterColor42, WaterColor43, WaterColor44, WaterColor45, WaterColor46, WaterColor47, WaterColor48, WaterColor49,
  WaterColor50, WaterColor51, WaterColor52, WaterColor53, WaterColor54, WaterColor55, WaterColor56, WaterColor57, WaterColor58, WaterColor59,
  WaterColor60, WaterColor61, WaterColor62, WaterColor63, WaterColor64, WaterColor65, WaterColor66, WaterColor67, WaterColor68, WaterColor69,
  WaterColor70, WaterColor71, WaterColor72, WaterColor73, WaterColor74, WaterColor75, WaterColor76, WaterColor77, WaterColor78, WaterColor79,
  WaterColor80, WaterColor81, WaterColor82, WaterColor83, WaterColor84, WaterColor85, WaterColor86, WaterColor87, WaterColor88, WaterColor89,
  WaterColor90, WaterColor91, WaterColor92, WaterColor93, WaterColor94, WaterColor95, WaterColor96, WaterColor97, WaterColor98, WaterColor99,
  WaterColor100
];

const consoleDebugging = false;

function getMap(type) {
  switch (type) {
    case 'space-1':
      return waterColorMap;
    case 'space-2':
      return waterColorMap;
    default:
      return waterColorMap;
  }
}

export function fetchNextImage(type, imageNumber) {
  if (consoleDebugging) {
    console.log(`FETCHING ${type}-${imageNumber}.txt`);
  }
  return fetch(getMap(type)[imageNumber])
    .then(r => {
      const text = r.text();
      return text;
    });
}
