const cols = 370;
const rows = 70;

const hideCharPercent = .005;

const lowLimit = 0;
const highLimit = 100;

// const consoleDebugging = false;

export function updateImageState(type, dir, currI) {
  let multiplier = .20;

  if (currI === lowLimit) {
    dir = 1;
  } else if (currI === highLimit) {
    dir = -1;
  }

  // console.log(currI)
  if (currI > 10 && currI < 90) {
    multiplier = .30;
    if (currI > 20 && currI < 80) {
      multiplier = .40;
      if (currI > 30 && currI < 70) {
        multiplier = .50;
        if (currI > 40 && currI < 60) {
          multiplier = .60;
          if (currI >= 48 && currI < 52) {
            multiplier = .15;
            if (currI === 50) {
              multiplier = .05;
            }
          }
        }
      }
    }
  }

  if(Math.random() < multiplier) {
    // console.log('Move Triggered');
    currI = currI + dir;
  }

  return {
    direction: dir,
    currentIndex: currI
  };
}

export function moveFramePosition(r, c, maxRows, maxCols, currentImageIndex) {
  if (currentImageIndex === 50) {
    const possibleRows = maxRows - rows;
    const possibleCols = maxCols - cols;
    const randomRow = Math.floor(Math.random() * possibleRows) + 1;
    const randomCol = Math.floor(Math.random() * possibleCols) + 1;

    const nextRow = randomRow;
    const nextCol = randomCol;
    // console.log('CHANGING IMAGE LOCATION!', 'r: ' + nextRow, 'c: ' + nextCol);
    return {row: nextRow, col: nextCol};
  } else {
    return {row: r, col: c};
  }
};

export function generateNextFrameV2(model, currentImageIndex, rowOffset, colOffset) {
  return modifyImage(model, rows, cols, currentImageIndex, rowOffset, colOffset);
};

function generateCharFromChar(modelLine, c, currentImageIndex) {
  let nextChar = modelLine[c];
  const multiplier = calcHideCharMultiplier(currentImageIndex.current);

  if(Math.random() < (hideCharPercent * multiplier)) {
    nextChar = ' ';
  }

  return nextChar;
}

function calcHideCharMultiplier(imageIndex) {
  let divider = 5;
  let multiplier = 1 / divider;

  if (imageIndex <= 50) {
    multiplier = imageIndex / divider;
  } else if (imageIndex < 100) {
    multiplier = (50 - (imageIndex % 50)) / divider;
  }

  return multiplier;
}

function modifyImage(model, _rows, _cols, currentImageIndex, rowOffset, colOffset) {
  let newModel = [];
  try {
    for(let r = rowOffset; r < _rows + rowOffset; r++) {
      const wholeStr = model[r];
  
      // eslint-disable-next-line
      const [first, second] = [wholeStr.slice(colOffset, colOffset + _cols), wholeStr.slice(_cols)];
      let nextLine = '';
      for(let c = 0; c < _cols; c++) {
        nextLine = nextLine + generateCharFromChar(first, c, currentImageIndex);
      }
      newModel.push(nextLine);
    }
  } catch (e) {
    console.log('something bad happened when modifying image...', e);
  }
  return newModel;
}
